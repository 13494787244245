<template>
  <div class="page page-projects">
    <Nav />
    <div class="container heading">
      <div class="row">
        <div class="col">
          <h1>Projects</h1>
          <hr />
        </div>
      </div>
    </div>
    <div class="projects">
      <div class="project" v-for="project in projects" :key="project.id">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <a
                v-if="project.production_link !== ''"
                :href="project.production_link"
                target="_blank"
              >
                <img class="thumbnail" :src="project.thumbnail" alt="" />
              </a>
              <a
                v-else-if="project.archive_link !== ''"
                :href="project.archive_link"
                target="_blank"
              >
                <img class="thumbnail" :src="project.thumbnail" alt="" />
              </a>
              <div v-else>
                <img class="thumbnail" :src="project.thumbnail" alt="" />
              </div>
              <router-link
                v-if="project.hasContent"
                class="title"
                :to="{ name: 'Project', params: {
                  project_id: project.id,
                  project_title: project.title.replaceAll(' ', '_')
                }}"
                ><h3 v-html="project.title"></h3></router-link
              >
              <div v-else class="title">
                <h3 v-html="project.title"></h3>
              </div>
              <hr />
              <p class="description" v-html="project.description"></p>
              <div class="links">
                <div>
                  <router-link
                    v-if="project.hasContent"
                    :to="{ name: 'Project', params: {
                      project_id: project.id,
                      project_title: project.title.replaceAll(' ', '_')
                    }}"
                    >Details</router-link
                  >
                  <a
                    v-if="project.production_link !== ''"
                    class="production_link"
                    :href="project.production_link"
                    target="_blank"
                    >Production</a
                  >
                  <a
                    v-if="project.archive_link !== ''"
                    class="archive_link"
                    :href="project.archive_link"
                    target="_blank"
                    >Archive</a
                  >
                </div>
                <div class="year" v-html="project.year"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'

const axios = require('axios')

export default {
  name: 'Projects',
  components: {
    Nav
  },
  data: function () {
    return {
      projects: []
    }
  },
  methods: {},
  beforeMount () {
    axios.get(this.$store.state.endpoint + 'list_projects').then((response) => {
      try {
        response = response.data
        if (response.status !== 'success') {
          throw response.error.length > 0 ? response.error[0] : 'Unknown Error'
        }
        response.data.forEach((project) => {
          this.projects.push(project)
        })
      } catch (e) {
        console.error(e)
      }
    })
  },
  mounted () {}
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/projectshead.scss';

h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}
.project {
  > .container {
    position: relative;
    padding-top: 2em;
    padding-right: 1.5em;
    padding-bottom: calc(2em + 1em);
    padding-left: calc(360px - 0.5em);
  }
  height: 360px;
  img.thumbnail {
    position: absolute;
    top: 2em;
    left: 1.5em;
    height: calc(360px - 4em);
  }
  .title {
    color: inherit;
    h3 {
      font-size: 1.2em;
      font-weight: 800;
    }
  }
  p.description {
    height: 150px;
    font-size: 0.8em;
    overflow: auto;
  }
  .links {
    position: absolute;
    left: calc(360px - 0.5em);
    right: 2em;
    bottom: 2em;
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    a {
      padding: 0 0.5em;
      color: $beige;
    }
    .year {
      display: inline-block;
    }
  }

  &:nth-child(2n + 1) {
    background: $key-yellow;
    color: $key-grey;
    > .container {
      padding-left: 2em;
      padding-right: calc(280px + 3.5em);
    }
    img.thumbnail {
      left: auto;
      right: 1.5em;
    }
    .links {
      left: 2em;
      right: calc(295px + 3.5em);
      a {
        color: inherit;
      }
    }
  }
  // &:nth-child(2n) {
  // }
}
@media (max-width: 768px) {
  .project {
    height: auto;
    > .container {
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
    }
    img.thumbnail {
      position: initial;
    }
    .title h3 {
      margin-top: 0.5rem;
    }
    p.content {
      height: auto;
    }
    .links {
      left: 15px;
      right: 15px;
    }
    &:nth-child(2n + 1) {
      > .container {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
      }
      .links {
        left: 15px;
        right: 15px;
      }
    }
  }
}
</style>
